import React, { useState } from "react";
import { Box, Divider, Paper, Typography, Button, Link, IconButton } from "@mui/material";
import { TEXT, BUTTON, CARDURL, URL, HELP } from "../constant";
import PheonixTextField from "../Components/PheonixTextField";
import SubmitButton from "../Components/PheonixButton";
import main from "../assets/CALLontheGO_icon_.png";
import link from "../assets/integration_line_logo.png";
import contact from "../assets/contacts.png";
import on from "../assets/on.png";
import ads from "../assets/ads.png";
import calender from "../assets/calculator.png";
import hub from "../assets/hub.png";
import mail from "../assets/mail.png";
import PheonixWizardModal from "../Components/PheonixWizardModel";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

const Help: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const { isOpen, toggleSidebar } = useSidebar();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModelopen = () => {
    setIsModalOpen(true);
  };

  const cardData = [
    {
      title: "Add new CallOnTheGo call lists as new CRM events",
      description: "CRM Connector + CallOnTheGo",
      cardUrl: CARDURL.FIRST_URL,
      imageUrl1: link,
      imageUrl2: main,
    },
    {
      title: "Add new Google contacts as new CallOnTheGo contacts",
      description: "CallOnTheGo + Google Contacts",
      cardUrl: CARDURL.SECOND_URL,
      imageUrl1: main,
      imageUrl2: contact,
    },
    {
      title:
        "Add new Gmail emails to new CallOnTheGo lists [Business Gmail Accounts Only]",
      description: "CallOnTheGo + Gmail",
      cardUrl: CARDURL.THIRD_URL,
      imageUrl1: main,
      imageUrl2: mail,
    },
    {
      title:
        "Add new or updated Google Calendar events to new CallOnTheGo lists",
      description: "CallOnTheGo + Google Calendar",
      cardUrl: CARDURL.FOURTH_URL,
      imageUrl1: main,
      imageUrl2: calender,
    },
    {
      title: "Add new CallOnTheGo contacts for lists as HubSpot CRM contacts",
      description: "CallOnTheGo + HubSpot",
      cardUrl: CARDURL.FIFTH_URL,
      imageUrl1: main,
      imageUrl2: hub,
    },
    {
      title: "Add new Ontraport contacts as CallOnTheGo contacts",
      description: "CallOnTheGo + Ontraport",
      cardUrl: CARDURL.SIXTH_URL,
      imageUrl1: main,
      imageUrl2: on,
    },
    {
      title: "Add new CallOnTheGo contacts as new Ontraport contacts",
      description: "Ontraport + CallOnTheGo",
      cardUrl: CARDURL.SEVENTH_URL,
      imageUrl1: on,
      imageUrl2: main,
    },
    {
      title: "Add new HubSpot contacts as new CallOnTheGo contacts",
      description: "CallOnTheGo + HubSpot",
      cardUrl: CARDURL.EIGHT_URL,
      imageUrl1: main,
      imageUrl2: hub,
    },
    {
      title: "Add new CallOnTheGo contacts as new or updated HubSpot contacts",
      description: "CallOnTheGo + HubSpot",
      cardUrl: CARDURL.NINE_URL,
      imageUrl1: main,
      imageUrl2: hub,
    },
    {
      title: "Create Contact on CallOnTheGo for new Google Ads Leads ",
      description: "CallOnTheGo + Google Ads",
      cardUrl: CARDURL.TENTH_URL,
      imageUrl1: main,
      imageUrl2: ads,
    },
  ];

  const renderCards = (data: any) => {
    return data.map((card: any, index: any) => (
      <a
        key={index}
        href={card.cardUrl}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box
          display="flex"
          alignItems="center"
          padding="10px"
          boxShadow={1}
          marginBottom="30px"
          border="1px solid #999c9e"
          borderRadius="4px"
          sx={{
            "&:hover": {
              borderColor: "#3d4592",
            },
          }}
        >
          <Box display="flex" alignItems="center" marginRight="10px" style={{}}>
            <img
              src={card.imageUrl1}
              alt={`${card.title} icon 1`}
              style={{
                marginRight: "5px",
                width: "24px",
                height: "24px",
                border: "1px solid #f3f3f4",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
            <img
              src={card.imageUrl2}
              alt={`${card.title} icon 2`}
              style={{
                width: "24px",
                height: "24px",
                border: "1px solid #f3f3f4",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </Box>
          <Box flex="1">
            <Typography
              variant="h6"
              color="#000000"
              fontSize={"14px"}
              fontWeight={"bold"}
            >
              {card.title}
            </Typography>
            <Typography variant="body2" color="#000000" fontSize={"14px"}>
              {card.description}
            </Typography>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#000000",
              width:isTablet? "18%": "10%",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            {BUTTON.USEZAP}
          </Button>
        </Box>
      </a>
    ));
  };

  const renderCardformobile = (data: any) => {
    return data.map((card: any, index: any) => (
      <a
        key={index}
        href={card.cardUrl}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="10px"
          boxShadow={1}
          marginBottom="30px"
          border="1px solid #999c9e"
          borderRadius="4px"
          sx={{
            "&:hover": {
              borderColor: "#3d4592",
            },
          }}
        >
          <Box display="flex" alignItems="center"alignSelf="flex-start"  marginBottom="10px">
            <img
              src={card.imageUrl1}
              alt={`${card.title} icon 1`}
              style={{
                marginRight: "5px",
                width: "24px",
                height: "24px",
                border: "1px solid #f3f3f4",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
            <img
              src={card.imageUrl2}
              alt={`${card.title} icon 2`}
              style={{
                width: "24px",
                height: "24px",
                border: "1px solid #f3f3f4",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </Box>
          <Box flex="1" textAlign="left" alignSelf="flex-start" marginBottom="10px">
            <Typography
              variant="h6"
              color="#000000"
              fontSize={"14px"}
              fontWeight={"bold"}
            >
              {card.title}
            </Typography>
            <Typography variant="body2" color="#000000" fontSize={"14px"}>
              {card.description}
            </Typography>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#000000",
              width: "80%",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            {BUTTON.USEZAP}
          </Button>
        </Box>
      </a>
    ));
  };
  
  return (
    <Box width="100%" bgcolor="#f3f3f4">
      { isMobile &&
       <Divider style={{ marginTop: !isOpen && isMobile ? "1rem" :isMobile ? "1rem" :"auto" }} /> }
      <Box padding="20px">
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          marginRight={{ md: "20px" }}
        >
          <Paper
            style={{
              width: isMobile ? "95%":isTablet? "98%":"100%",
              height: "auto",
              fontFamily: "sans-serif",
              color: "#676a6c",
              fontSize: "13px",
              padding: "10px",
            }}
          >
            {TEXT.INTEGRATIONS}
            <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
            <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
              {HELP.TEXT1}
            </Typography>
            <Typography
              style={{
                fontWeight: "normal",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <a
                href="https://zapier.com/apps/callonthego/integrations"
                style={{ color: "#478DCB", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer" 
              >
                Zapier
              </a>{" "}
              {HELP.TEXT2}
            </Typography>
            <Typography
              style={{
                fontWeight: "normal",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              {HELP.TEXT3}
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              {HELP.TEXT4}{" "}
            </Typography>
            <Typography
              style={{
                fontWeight: "normal",
                fontSize: "14px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {HELP.TEXT5}{" "}
              <a
                href="https://zapier.com/sign-up"
                style={{ color: "#478DCB", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer" 
              >
                Zapier account{" "}
              </a>
              {HELP.TEXT6}
            </Typography>
            {isMobile ? renderCardformobile(cardData) : renderCards(cardData)}
            <Typography
              variant="body1"
              color="#000000"
              style={{ padding: "0px 0px 20px 0px ", textAlign: "center" }}
            >
              {TEXT.NOT_SEEING} <br />
              <a
                href={URL.CREATE_FROM_SCRATCH_URL}
                style={{ color: "#3d4592" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {HELP.TEXT7}
              </a>{" "}
              or{" "}
              <a
                href={URL.LEARN_MORE_URL}
                style={{ color: "#3d4592" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {HELP.TEXT8}
              </a>
              .
            </Typography>
            <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
              {HELP.TEXT9}
            </Typography>
            <Typography
              style={{
                fontWeight: "normal",
                fontSize: "14px",
                marginTop: "10px",
                marginLeft: "30px",
              }}
            >
              1. Log in to your{" "}
              <a
                href="https://zapier.com/app/login"
                style={{ color: "#478DCB", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer" 
              >
                Zapier account
              </a>{" "}
              or create a new account.
              <br />
              2. Navigate to "Connected Accounts" from the top menu bar.
              <br />
              3. Now click on "Connect new account" and search for
              "CallOnTheGo".
              <br />
              4. Use your credentials to connect your CallOnTheGo account to
              Zapier.
              <br />
              5. Once that's done you can start creating an automation! Use a
              pre-made Zap or create your own with the Zap Editor. Creating a
              Zap requires no coding knowledge and you'll be walked step-by-step
              through the setup.
              <br />
              6. Need inspiration? See everything that's possible with{" "}
              <a
                href="https://zapier.com/apps/callonthego/integrations"
                style={{ color: "#478DCB", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer" 
              >
                CallOnTheGo and Zapier
              </a>
            </Typography>
          </Paper>
        </Box>
        <Box>
          <Paper>    
            <Typography
              style={{
                marginTop: "40px",
                width: "100%",
                height: "auto",
                fontFamily: "sans-serif",
                fontWeight: "bold",
                color: "#676a6c",
                fontSize: "13px",
                padding: "10px",
              }}
            > <span>
              <IconButton size="small" style={{ marginTop : "-1px" }}>
              <HelpOutlinedIcon />
              </IconButton></span>
              Help
            </Typography>
            <Typography
              style={{
                fontWeight: "normal",
                fontSize: "14px",
                marginTop: "-5px",
                marginLeft: "30px",
                paddingBottom: "30px",
                color: "#676a6c",
              }}
            >
              Email:{" "}
              <a href="mailto:support@CallOnTheGo.com"
               style={{ color: "#478DCB", textDecoration: "none" }}>
                support@CallOnTheGo.com
              </a>
              <br />
              Phone: 1-888-301-2726
              <br />
              Text: 325.200.0007
              <br />
            </Typography>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "30px",
          }}
        >
          <iframe
            width={!isOpen && isMobile ? "100%" :isMobile ? "100%" : "620"}
            height={!isOpen && isMobile ? "20%" :isMobile ? "20%" :"315"}
            src="https://www.youtube.com/embed/8unoEhnHC5c?si=apI4oF5Gh1c8w9y4"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          {!isMobile && 
          <iframe
            width= "620"
            height="315"
            src="https://www.youtube.com/embed/iCp2RlsXGws?si=CrIxl0AeaOkX3ZwE"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        }
        </Box>
        {isMobile && 
        <iframe
            width={!isOpen && isMobile ? "100%" :isMobile ? "100%" : "620"}
            height={!isOpen && isMobile ? "20%" :isMobile ? "20%" :"315"}
            style={{ marginTop: "20px" }}
            src="https://www.youtube.com/embed/iCp2RlsXGws?si=CrIxl0AeaOkX3ZwE"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        }
      </Box>
    </Box>
  );
};

export default Help;
