import React, { useEffect, useRef, useState } from "react";
import { Divider, IconButton, Alert } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSidebar } from "./sidebarcontext";
import { config } from "../config";

const AdminDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isOpen } = useSidebar();
  const filterId = "NATIVE_FILTER-2kBw6BxQblD_5GbYnbYqA";
  const token = localStorage.getItem("authtoken");
  const [Message, setMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState(true);

  const dashboardUrl = `${
    config.internalDashboard
  }&native_filters=(${filterId}:(__cache:(label:'${token}',validateStatus:!f,value:!('${token}')),extraFormData:(filters:!((col:token,op:IN,val:!('${token}'))),apply:!t),filterState:(label:'${token}',validateStatus:!f,value:!('${token}')),id:${filterId},ownState:()))&random=${Math.random()}`;

  useEffect(() => {
    const storedMessage = sessionStorage.getItem("message");
    if (storedMessage) {
      setMessage(storedMessage);
      sessionStorage.removeItem("message");
    }
  }, []);

  useEffect(() => {
    const storedMessages = sessionStorage.getItem("signup");
    if (storedMessages) {
      setMessage(storedMessages);
      sessionStorage.removeItem("signup");
    }
  }, []);

  return (
    <div>
      {Message && showAlert && (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowAlert(false)}
            ></IconButton>
          }
          style={{ color: "#3c763d" }}
        >
          {Message}
        </Alert>
      )}
      {isMobile && (
        <Divider
          style={{
            marginTop:
              !isOpen && isMobile ? "0.5rem" : isMobile ? "0.5rem" : "auto",
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          marginLeft: "0px",
          marginTop: isMobile ? "-10px" : "-5px",
          backgroundColor: "#f3f3f4",
        }}
      >
        <iframe src={dashboardUrl} width="100%" height="800px"></iframe>
      </div>
    </div>
  );
};

export default AdminDashboard;
