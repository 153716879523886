import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  Button,
  Checkbox,
  Divider,
  Typography,
  Chip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import PheonixGroupModalProps from "../interfaces/PheonixModel"; 
import axios from "axios";
import { config } from "../config";
import { useTheme, useMediaQuery } from "@mui/material";
import themestyle from "../themestyle";

interface OptionType {
  id: string;
  name: string;
}

const PheonixListModal = <T,>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
}: PheonixGroupModalProps<T>) => {
  const typedFormData = formData as Record<string, string>;
  const token = localStorage.getItem("authtoken");
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<OptionType[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [isListNmae, setIsListName] = useState(false);

  const handleContactSelection = (event: any, value: OptionType[]) => {
    setSelectedContacts(value);
    handleFieldChange("List", "", value); 
  };

  const handleConfirmContact = () => {
    if (onConfirm) {
      onConfirm();
    }
    setIsListName(false);
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    handleFieldChange("Name", "");
    handleFieldChange("Description", "");
    setIsListName(false);
  };

  const handleFieldChange = (label: string, value: string, value1: OptionType[] = []) => {
    let errorMessage = "";

    if (label === "Name") {
      if (!value) {
        errorMessage = "Phone number is required.";
        setIsListName(false);
      } else {
        setIsListName(true);
      }
    }
    if (setFormData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [label]: value,
        List: value1.map(option => option.id),
      }));
    }
  };

  const fetchoption = async () => {
    try {
      const response = await axios.get(
        config.baseUrl + "/v1/lists", {
        headers: {
          "x-cotg-authtoken": token,
        },
      });

      const id: OptionType[] = response.data.lists.map((item: { id: string , name: string}) => ({
        id: item.id,
        name: item.name,
      }));
      setOptions(id);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    fetchoption();
  }, [token]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "white",
            width:isMobile? "90%" : "30%",
            height: "auto",
            boxShadow: "0px 4px 4px 0px #00000040",
            border: "1px solid #0000001F",
            margin:isMobile? "0px 0px 350px 0px": "0px 10px 350px 100px",
          },

        }}
      >
        <DialogTitle
         sx={{
          fontSize: "24px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#686A6C",
          }}
          >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 5,
            top: 0,
            color: "#999c9e",
          }}
        >
          <CloseIcon sx={{ color: "#999c9e",fontSize: "1.2rem" }} />
        </IconButton>
        <Divider style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }} />
        <DialogContent>
          <DialogContentText style={{ marginTop: "-30px" }}>
            <Grid container spacing={3}>
              {fields.map((field) => (
                <Grid item xs={12} key={field.label}>
                  {field.type === "textarea" ? (
                    <TextareaAutosize
                      aria-label={field.label}
                      placeholder={field.label}
                      minRows={5}
                      style={{ width: "100%" }}
                      value={typedFormData[field.label]}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                    />
                  ) : field.type === "autocomplete" ? (
                    <Autocomplete
                    multiple
                    id={field.label.toLowerCase().replace(/\s/g, "-")}
                    options={options}
                    getOptionLabel={(option) => option.name}
                    disableCloseOnSelect
                    onChange={handleContactSelection}
                    value={selectedContacts}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderTags={(value: OptionType[], getTagProps) =>
                      value.map((option: OptionType, index: number) => (
                        <Chip
                          variant="filled"
                          label={option.name}
                          {...getTagProps({ index })}
                          sx={{
                            background: '#F1F1F1',
                            color: '#676a6c',
                            fontSize: '10px',
                            borderRadius:"0.9px"
                          }}
                        />
                      ))
                    }
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field.label}
                        variant="outlined"
                      />
                    )}
                  />
                  ) : (
                    <TextField
                      fullWidth
                      label={field.label}
                      variant="outlined"
                      value={typedFormData[field.label]}
                      onChange={(e) =>
                        handleFieldChange(field.label, e.target.value)
                      }
                     
                      size="small"
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Divider style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }} />
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            sx={{
              width: "15%",
              height: "30px",
              backgroundColor: "#D32F2F",
              color: "white",
              textTransform: "none",
              marginTop: isMobile ? "-5%":"-5%",
              "&:hover": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
              "&:focus": {
                backgroundColor: "#D32F2F",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmContact}
            color="primary"
            sx={{
              width: "15%",
              textTransform: "none",
              height: "30px",
              marginTop:isMobile ? "-5%": "-5%",
              backgroundColor: !isListNmae ? "#E0E0E0":themestyle.colors.primary,
              color: "white",
              marginRight: "5%",
              "&:hover": {
                backgroundColor: !isListNmae ? "#E0E0E0": themestyle.colors.primary,
                color: "white",
              },
              "&:focus": {
                backgroundColor: !isListNmae ? "#E0E0E0": themestyle.colors.primary,
                color: "white",
              },
            }}
            disabled={!isListNmae}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PheonixListModal;
